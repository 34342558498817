<template>
  <div class="">
    <div class="min-h-screen w-full bg-purple-pattern p-10 mt-0">
      <div class="container mx-auto flex flex-col md:flex-row items-center justify-between text-white">
        <div class="flex items-center font-extrabold text-2xl mb-5 md:mb-0">
          <img src="../assets/logo.png" style="width: 2em; height: 2em;" class="mr-1"/>
          ChainFaces <span class="ml-2 text-yellow-400 font-script">Arena</span>
        </div>
        <ConnectButton/>
      </div>
      <div class="container mx-auto pt-10 text-white">
        <div class="text-8xl md:text-9xl text-center my-20">
          <ChainFaceCarousel/>
        </div>
        <div class="mx-auto mt-10 w-full lg:w-1/2 mt-2 text-justify text-xl leading-8">
          ChainFaces Arena is the much anticipated spiritual successor to the original on-chain generative ASCII text
          faces. The smart contract generator produces randomised faces which will be revealed once the timer runs out
          or the maximum number of faces are minted.<br/><br/>Throw your ChainFace into the totally non-OSHA-compliant
          arena and out last your competition to win a share in the bounty. The longer your warrior survives the more
          kudos it receives and the greater your potential reward. But watch out, the arena is full of face-eating lions
          and if you're one of the unlucky ones selected for lunch each round your warrior will be forever lost to The
          Happy Face Place&trade;.
        </div>
        <div class="mx-auto my-10 lg:my-20 w-1/2 xl:w-1/3 text-3xl grid grid-cols-1 md:grid-cols-2 gap-4 text-center">
          <router-link :to="{name: 'Mint'}" class="btn">Mint</router-link>
          <router-link :to="{name: 'Arena'}" class="btn">Arena</router-link>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Footer from '../components/Footer';
import ConnectButton from '../components/ConnectButton';
import ChainFaceCarousel from '../components/ChainFaceCarousel';

export default {
  name: 'Home',
  components: { ChainFaceCarousel, ConnectButton, Footer },
};
</script>
