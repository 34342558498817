<template>
  <div>
    <span v-html="leftFace"/><span v-html="leftEye"/><span v-html="mouth"/><span v-html="rightEye"/><span
      v-html="rightFace"/>
  </div>
</template>

<script>

const leftFaceArray = ['ლ', 'ᕦ', '(ง', '𐐋', 'ᖳ', 'Ƹ', 'ᛩ', '⦃', '{', '⦗', '〈', '⧼', '|', '〘', '〚', '【', '[', '❪'];
const leftEyeArray = ['⚈', '⚙', '⊗', '⋗', ' ͡°', '◈', '◬', '≻', '᛫', '⨕', '★', 'Ͼ', 'ᗒ', '◠', '⊡', '⊙', '▸', '˘', '⦿', '●'];
const mouthArray = ['෴', '∪', 'ᨏ', '᎔', '᎑', '⋏', '⚇', '_', '۷', '▾', 'ᨎ', 'ʖ', 'ܫ', '໒', '𐑒', '⌴', '‿', '𐠑', '⌒', '◡', '⥿', '⩊'];
const rightEyeArray = ['⚈', '⚙', '⊗', '⋖', ' ͡°', '◈', '◬', '≺', '᛫', '⨕', '★', 'Ͽ', 'ᗕ', '◠', '⊡', '⊙', '◂', '˘', '⦿', '●'];
const rightFaceArray = ['ლ', 'ᕤ', ')ง', '𐐙', 'ᖰ', 'Ʒ', 'ᚹ', '⦄', '}', '⦘', '〉', '⧽', '|', '〙', '〛', '】', ']', '❫'];

export default {
  name: 'ChainFaceCarousel',
  data() {
    return {
      leftFace: '(',
      leftEye: '-',
      mouth: '.',
      rightEye: '-',
      rightFace: ')',
    };
  },
  mounted() {
    this.leftFace = leftFaceArray[Math.floor(Math.random() * leftFaceArray.length)];
    this.leftEye = leftEyeArray[Math.floor(Math.random() * leftEyeArray.length)];
    this.mouth = mouthArray[Math.floor(Math.random() * mouthArray.length)];
    this.rightEye = rightEyeArray[Math.floor(Math.random() * rightEyeArray.length)];
    this.rightFace = rightFaceArray[Math.floor(Math.random() * rightFaceArray.length)];

    this.$options.timer = setInterval(this.randomise.bind(this), 500);
  },
  unmounted() {
    clearInterval(this.$options.timer);
  },
  methods: {
    randomise() {
      let part = Math.floor(Math.random() * 5);

      switch (part) {
        case 0:
          this.leftFace = leftFaceArray[Math.floor(Math.random() * leftFaceArray.length)];
          break;
        case 1:
          this.leftEye = leftEyeArray[Math.floor(Math.random() * leftEyeArray.length)];
          break;
        case 2:
          this.mouth = mouthArray[Math.floor(Math.random() * mouthArray.length)];
          break;
        case 3:
          this.rightEye = rightEyeArray[Math.floor(Math.random() * rightEyeArray.length)];
          break;
        case 4:
          this.rightFace = rightFaceArray[Math.floor(Math.random() * rightFaceArray.length)];
          break;
      }
    }
  }
};
</script>